$mainColor: #24075e;
$subColor: rgb(252, 210, 132);
$width: 768px;

@mixin mobile {
    @media (max-width: #{$width}){
      @content
    }
}

