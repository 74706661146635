@import "../../global.scss";


.shopify{
  display: flex;
  overflow: hidden;
  background-color: white;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .leftShop{
    flex: 1.7;
    overflow: hidden;
    
    .shopLeftContainer{
      width: 700px;
      height: 520px;
      margin: 170px 10px;
      display: flex-end;
      justify-content: center;
      float: right;
      
    @include mobile {
      width: 100%;
      height: 100%;
    }
      img{
        width: 100%;
        height: 80%;
      }
    }
  }

  .rightShop{
    flex: 1.3;
    position: relative;
    
    @include mobile {
      overflow: hidden;
    }
    .rightRightContainer{
      margin-top: 200px;
      margin-right: 80px;

      @include mobile {
        margin-top: 20px;
        margin-right: 0px;
        overflow: hidden;
      }

      .animatedFont{
        font-size: 50px;
        color: orange;
        animation: arrowBlink 1.3s infinite;
      }

      h1{
        font-size: 60px;
        height: 30vh;
        margin: 10px 0;

        @include mobile {
          font-size: 35px;
        }
      }

      h2{
        font-size: 35px;
        
        @include mobile {
          font-size: 20px;
        }
      }
      
      a{
        position: absolute;
        bottom: 50px;
        left: 40%;

        img{
          width: 40px;
          animation: arrowBlink 1.3s infinite;
        }
      }
    }
}
}