@import "../../global.scss";

.intro {
  background-color: white;
  display: flex;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }
  .left{
    flex: 0.5;
    overflow: hidden;

    .imgContainer{
      width: 700px;
      height: 700px;
      background-color: $subColor;
      border-radius: 50%;
      display: flex-end;
      justify-content: center;
      float: right;
      overflow-y: hidden;

      @include mobile {
        align-items: flex-start;
      }

      img{
        height: 90%;
        margin-top: 69px;

        @include mobile {
          height: 70%;
          margin-left: 30px;
          margin-top: -22px;
        }
      }
    }
  }

  .right{
    flex: 0.5;
    position: relative;
    .wrapper{
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        padding-left: 0;
        align-items: center;
      }

      h1{
        font-size: 60px;
        height: 10vh;
        margin: 10px 0;

        @include mobile {
          font-size: 40px;
        }
      }
      h2{
        font-size: 35px;
        height: 8vh;
        
        @include mobile {
          font-size: 20px;
        }
      }
      h3{
        font-size: 30px;
        height: 10vh;

        @include mobile {
          font-size: 20px;
        }
        span{
          font-size: inherit;
          color:rgb(243, 159, 2);
        }

        .ityped-cursor{
          animation: blink 0.5s infinite;
        }

        @keyframes blink {
          50%{
            opacity: 1;
          }
          100%{
            opacity: 0;
          }
          
        }
      }
    }
    a{
      position: absolute;
      bottom: 50px;
      left: 40%;

      img{
        width: 40px;
        animation: arrowBlink 1.3s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}