@import "../../global.scss";

.menu{
  width: 300px;
  height: 100vh;
  background-color: $mainColor;
  position: fixed;
  top: 0;
  right: -300px; // 전체 width 300인 상태에서 -300 값을 주면 0이 되므로
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;

  &.active{
    right: 0; // active일때 가져오도록 설정해준다
  }

  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 30px;
    font-weight: 300;
    color:white;
    width: 60%;
    li{
      margin-bottom: 20px;
      a{
        font-size: inherit;
        color: inherit;
        text-decoration: none;
      }

      &:hover {
        font-weight: 600;
      }
    }
  }
}