@import "../../global.scss";

.contact {
  background-color: white;
  display: flex;

  @include mobile{
    flex-direction: column;
  }

  .left{
    flex: 1;
    overflow: hidden;
    align-self: center;
    
    img{
      width: 100%;
      height: 100%;
    }
  }

  .right{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2{
      font-size: 50px;

      @include mobile{
        font-size: 30px;
      }
    }

    form{
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      input{
        width: 90%;
        height: 30px;
        font-size: 14px;

        @include mobile{
          width: 200px;
          height: 20px;
        }
      }

      textarea{
        width: 90%;
        height: 200px;
        font-size: 14px;

        @include mobile{
          width: 200px;
          height: 100px;
        }
      }

      #sendButton{
        width: 150px;
        height: 30px;
        color:white;
        background-color: rgb(247, 151, 8);
        border: none;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;

        &:focus{
          outline: none;
        }
      }
      span {
        color: green;
      }
    }
  }
}
.status-message {
  opacity: 0;
}
.success {
  opacity: 1;
  color: green;
}
.failure {
  opacity: 1;
  color: red;
}