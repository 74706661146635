@import "../../global.scss";

.testimonials {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile{
    justify-content: space-around;
  }

  h1{
    font-size: 50px;
    margin-top: 40px;
    overflow: hidden;

    @include mobile{
      font-size: 20px;
    }
  }

  .container{
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile{
      flex-direction: column;
      height: 100%;
    }
    .card{
      width: 250px;
      height: 70%;
      border-radius: 10px;
      box-shadow: 0px 0px 15px -8px black;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px;
      transition: all 1s ease;

      @include mobile{
        height: 180px;
        margin: 10px 0;
      }

      &.featured {
        width: 300px;
        height: 75%;
        margin: 0 30px;

        @include mobile{
          width: 250px;
          height: 180px;
          margin: 3px;
        }
      }

      &:hover{
        transform: scale(1.2);
      }

      .top{
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img{
          &.left, &.right{
            height: 35px;
          }
          &.user{
            height: 60px;
            width: 60px;
            border-radius: 10%;
            object-fit: scale-down;
            margin: 20px;

            @include mobile{
              width: 35px;
              height: 35px;
            }
          }
        }
      }
      .center {
        padding: 10px;
        border-radius: 10px;
        background-color: rgb(243, 238, 226);
        overflow: hidden;

        @include mobile{
          font-size: 12px;
          padding: 5px;
          height: 80px;
        }
      }

      .bottom{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;

        @include mobile{
          flex-direction: row;
          justify-content: space-around;
        }
        h3{
          margin-bottom: 15px;
          font-size: 20px;

          @include mobile{
            font-size: 13px;
            margin-top: 15px;

          }

        }
        h4{
          font-size: 15px;
          color: gray;

          @include mobile{
            font-size: 12px;
            margin-top: 15px;
          }
        }
      }
    }
  }
}