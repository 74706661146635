@import "../../global.scss";

.topbar {
  width: 100%;
  height: 70px;
  background-color: white;
  color: $mainColor;
  position: fixed;
  top: 0;
  z-index: 2;
  overflow: hidden;
  .wrapper{
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left{
      display: flex;
      align-items: center;
      .mylogo{
        font-size: 40px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
        margin-right: 40px;
      }
      .itemcontainer{
        display: flex;
        align-items: center;
        margin-left: 30px;

        @include mobile{
          display: none;
        }
        .icon{
          font-size: 28px;
          margin-right: 5px;
        }
        a {
          text-decoration: none;
          text-decoration-color: none;
          color: $mainColor;
        }
        span {
          font-size: 20px;
          font-weight: 400;
        }
      }
    }
    .right{

      .hamburger{
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span{
          width: 100%;
          height: 3px;
          background-color: $mainColor;
          transform-origin: left; //토글바 엑스표 만들기
          transition: all 2s ease; // 토글바 움직임 속도
        }
      }
    }
  }
  
  &.active{
    background-color: $mainColor;
    color: white;

    .hamburger{
      span{
        &:first-child{
          background-color: white;
          transform: rotate(45deg); //토글바 엑스표 만들기
        }
        &:nth-child(2){
          opacity: 0;
        }
        &:last-child{
          background-color: white;
          transform: rotate(-45deg); //토글바 엑스표 만들기
        }
      }
    }
  }
}