@import "../../global.scss";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.portfolio {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1{
    font-size: 60px;
    height: 10vh;
    margin-bottom: 10px;
    overflow-y: hidden;

    @include mobile{
      font-size: 40px;
      overflow: hidden;
    }
  }

  .listCategory{
    margin: 10;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile{
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
      height: 18vh;
    }

    li{
      font-size: 14px;
      margin-right: 50px;
      padding: 7px;
      border-radius: 10px;
      cursor: pointer;
      overflow-y: hidden;

      &.active{
        background-color: $mainColor;
        color: white;
      }
    }
  }

  .container{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;

    @include mobile{
      width: 100%;
      height: 100%;
    }
    .item{
      width: 30%;
      height: 40%;
      border-radius: 20px;
      border:1px solid rgb(240, 239, 239);
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all 0.5s ease;
      cursor: pointer;
      z-index: 0;

      @include mobile{
        width: 70%;
        height: 45%;
        margin-top: 20px;
      }
      h3{
        position: absolute;
        font-size: 20px;
      }

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }

      &:hover{
        background-color: $mainColor;
        img{
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }
}

