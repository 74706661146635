@import "../../global.scss";

.works {
  background-color: rgb(245, 190, 12);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .arrow{
    height: 80px;
    position:absolute;
    cursor: pointer;

    @include mobile{
      display: none;
    }
    &.leftArrowImg{
      left: 100px;
    }

    &.rightArrowImg{
      right: 100px;
    }
  }
  .slider{
    height: 350px;
    display: flex;
    position: absolute;

    left: 0;
    transition: all 1s ease;
    
    @include mobile{
      height: 100vh;
      flex-direction: column;
      justify-content: center;
    }

    .container{
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      
      .item{
        width: 800vw;
        height: 100%;
        margin-left: 20.5%;
        background-color: white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile{
          width: 80%;
          height: 180px;
          margin: 10px 0;
        }
        .left{
          flex: 4;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .leftContainer{
            width: 90%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          
            .imgContainer{
              width: 40px;
              height: 40px;
              border-radius: 50px;
              background-color: rgb(229, 164, 140);
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;

              img{
                width: 100%;
              }
            }
            .infoNote {
              font-size: 15px;
              font-weight: 800;
            }
            h2{
              font-size: 20px;
              overflow: hidden;

              @include mobile{
                font-size: 18px;
              }
            }
            p{
              font-size: 13px;
              overflow: hidden;

              @include mobile{
                display: none;
              }
            }
            span{
              font-size: 12px;
              font-weight: 600;
              text-decoration: none;
              cursor: pointer;
              overflow: hidden;

              @include mobile{
                margin-top: 5px;
              }
            }
          }
        }

        .right{
          flex: 8;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow-y: hidden;

          img{
            width: 400px;
            transform: rotate(-10deg);
          }
        }
      }
    }
  }

  
}